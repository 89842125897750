<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">
        {{
        $t("AUTH.GENERAL.NO_ACCOUNT")
        }}
      </span>&nbsp;&nbsp;
      <router-link
        class="kt-link kt-login__signup-link"
        :to="{ name: 'register' }"
      >{{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}</router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <br />
          <br />
          <br />
          <h3>Enter verification code</h3>
          <p>
            We have sent you a temporary verification code to your email.
            <br />Enter the code verify this device.
          </p>
        </div>
        <div v-if="this.verification_errors.length>0">
          <div
            role="alert"
            v-for="(error, i) in verification_errors"
            :key="i"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-danger text-center"
          >{{ error }}</div>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="verifyAccount">
          <verification-code v-model="code" :required="true"></verification-code>

          <b-button
            type="submit"
            id="kt_submit"
            class="btn btn-primary btn-elevate kt-login__btn-primary text-white kt-spinner--light kt-spinner--right pull-right"
          >Verify</b-button>
        </b-form>

        <!--end::Form-->
        <p>
          <a href="#" @click="isBusy=!isBusy">Didn't receive a code?</a>
        </p>
        <p>It may take upto 10-15 minutes to receive an email. Please make sure to check your 'SPAM' & 'Promotional' area in your email.</p>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="resendVerificationEmail" v-if="isBusy">
          <!-- <div role="alert" class="alert alert-info">
            <div class="alert-text">
              Use account <strong>admin@demo.com</strong> and password
              <strong>demo</strong> to continue.
            </div>
          </div>-->

          <b-form-group id="example-input-group-1" label label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              name="example-input-1"
              placeholder="Enter your email"
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">Please enter a valid email address.</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Divider-->
          <div class="kt-login__divider">
            <div class="kt-divider"></div>
          </div>
          <!--end::Divider-->

          <vue-recaptcha
            ref="invisibleRecaptcha"
            :sitekey="sitekey"
            :loadRecaptchaScript="true"
            size="invisible"
            @error="onError"
          ></vue-recaptcha>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <router-link
              class="btn btn-default btn-elevate kt-login__btn-default"
              :to="{ name: 'login' }"
            >Back</router-link>

            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-info text-white"
              :disabled="emailVerificationMailRequested"
            >{{emailVerificationMailRequested ? 'Please wait for a minute' :'Click here to resend your verification code.'}}</b-button>
          </div>
          <!--end::Action-->
        </b-form>
      </div>
      <vue-snotify></vue-snotify>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>


<style scope>
.form-control {
  border: 1px solid !important;
}
</style>



<script>
import { mapState } from "vuex";
import { LOGIN } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import VerificationCode from "./VerificationCode";
import ApiService from "@/common/api.service";
import VueRecaptcha from "vue-recaptcha";
import userService from "@/services/user/UserService";
const UserService = new userService();

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      emailVerificationMailRequested: false,
      isBusy: false,
      code: "",
      token: "",
      errors: "",
      verification_errors: "",
      form: {
        email: ""
      }
    };
  },
  components: {
    VerificationCode,
    VueRecaptcha
  },
  created: function() {
    if (this.$route.query.code) this.code = this.$route.query.code;

    this.token = this.$route.query.token ? this.$route.query.token : "";
  },

  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    verifyAccount() {
      ApiService.post("user/verify", {
        verification_code: this.code,
        token: this.token,
        is_used: 0
      })
        .then(response => {
          this.$snotify.success(response.data.msg);
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 5000);

        })
        .catch(({ response }) => {
           this.$snotify.error(response.data.errors);
        });

      setTimeout(() => {
        this.verification_errors = [];
      }, 2000);
    },

    onError: function() {
      this.recaptchaCheked = false;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resendVerificationEmail() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.emailVerificationMailRequested = true;
      let formData = { email: this.$v.form.email.$model };
      UserService.requesRegistrationEmailVerificationCode(formData)
        .then(res => {
          this.$snotify.success(res.data.msg);
        })
        .catch(res => {
          this.$snotify.error(res.data.msg);
        });

      setTimeout(() => {
        this.emailVerificationMailRequested = false;
      }, 60000);
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
    }
  },
  watch: {
    /*  code: function(val) {
      if (val && val.length === 6) {
        this.verifyAccount();
      }
    } */
  }
};
</script>

