import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class userService {

    requestGoogleAuthenticator() {
        let url = API_URL + 'user/google-authenticator'
        return apiService.get(url);
    }

    activateGoogleAuthenticator(data) {
        let url = API_URL + 'user/activate/google-authenticator'
        return apiService.post(url, data);
    }
    deActivateGoogleAuthenticator() {
        let url = API_URL + 'user/deactivate/google-authenticator'
        return apiService.post(url);
    }

    sendChangeEmailMail(email){
        let url =  `${API_URL}user/change/email/${email}/mail`;
        return apiService.get(url);
    }


    checkVerificationEnabled(data) {
        let url = API_URL + 'user/check/verification-enabled'
        return apiService.post(url, data);
    }

    verifyGoogleVerificationCode(data) {
        let url = API_URL + 'user/verify/google-verification-code'
        return apiService.post(url, data);
    }

    verifyEmailVerificationCode(data) {
        let url = API_URL + 'user/verify/email-verification-code'
        return apiService.post(url, data);
    }
    sendMobileVerificationCode() {
        let url = API_URL + 'user/send/verification-code'
        return apiService.get(url);
    }
    verifyMobile(data) {
        let url = API_URL + 'user/verify/mobile'
        return apiService.post(url, data);
    }

    update(data) {
        let url = API_URL + 'user/update'
        return apiService.post(url,data);

    }

    requesEmailVerificationCode(data)
    {
        let url = API_URL+ 'user/request/verification-code'
        return apiService.post(url,data);
    }

    requesRegistrationEmailVerificationCode(data)
    {
        let url = API_URL+ 'user/resend/register/verification'
        return apiService.post(url,data);
    }

    verifyAndChangeEmail(user_id, data)
    {
        let url =  `${API_URL}user/change/${user_id}/email`;
        return apiService.post(url,data);
    }

}
